import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  color: var(--white);
  background-color: var(--color-primary);
  padding: 2rem 4rem;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #9c285f;
  }

  a {
    color: var(--white);
  }
`;

const Button = (props) => {
  return (
    <StyledButton {...props} type={props.type || "button"}>
      {props.children}
    </StyledButton>
  );
};

export default Button;
