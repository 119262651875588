import React, { useContext, useState, useRef } from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import ReCAPTCHA from "react-google-recaptcha";
import { handleSubscriptionFormSubmit } from "../utils/handleFormSubmits";
import respond from "../styles/abstracts/mediaqueries";

import Button from "../components/Button";
import AppContext from "../context/AppContext";

const StyledAlternativeSubscriptionForm = styled.div`
  ${respond(
    "phone-port",
    css`
      width: 90% !important;
      margin: 0 auto;
    `
  )}
  .form-title {
    color: var(--white);
    font-weight: 200;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  .input {
    padding-left: 1rem;
    width: 100%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    color: var(--white);
    height: 5rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  button {
    display: block;
  }

  .checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-top: 0;
    margin-bottom: 2rem;

    label {
      font-size: 1.6rem;
      font-family: var(--alternative-font);
      letter-spacing: 1px;
    }
  }

  .checkbox {
    ${respond(
      "phone-port",
      css`
        width: 10rem;
      `
    )}
  }

  .submit-button {
    ${respond(
      "phone-port",
      css`
        margin: 4rem auto 0 auto;
      `
    )}
  }
`;

const AlternativeSubscriptionForm = ({ className }) => {
  const recaptchaRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    privacy: false,
  });
  const {
    strapiNicoleWebsite: {
      homePage: {
        subscriptionForm: { privacyPolicy, cta, emailPlaceholder, namePlaceholder },
      },
    },
  } = useStaticQuery(query);

  const { setAlertState } = useContext(AppContext);

  function handleChange(e) {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  }

  return (
    <StyledAlternativeSubscriptionForm className={className}>
      <ReCAPTCHA
        ref={recaptchaRef}
        style={{ zIndex: 600 }}
        size="invisible"
        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
      />
      <h6 className="form-title">Want to keep up with the latest?</h6>
      <form onSubmit={(e) => handleSubscriptionFormSubmit(e, formData, setAlertState, recaptchaRef)}>
        <input
          type="text"
          id="footer-name"
          name="name"
          className="input"
          placeholder={namePlaceholder}
          onChange={handleChange}
          value={formData.first_name}
        />
        <input
          type="email"
          id="footer-email"
          name="email"
          className="input"
          placeholder={emailPlaceholder}
          onChange={handleChange}
          value={formData.email}
        />
        <div className="checkbox-container">
          <input
            type="checkbox"
            className="checkbox"
            name="privacy"
            id="footer-privacy"
            onChange={handleChange}
            value={formData.privacy}
            checked={formData.privacy}
          />
          {/* eslint-disable-next-line */}
          <label
            name="privacy"
            htmlFor="footer-privacy"
            className="label"
            dangerouslySetInnerHTML={{ __html: privacyPolicy }}
          />
        </div>

        <Button type="submit" className="submit-button">
          {cta}
        </Button>
      </form>
    </StyledAlternativeSubscriptionForm>
  );
};

export const query = graphql`
  query AlternativeSubscriptionForm {
    strapiNicoleWebsite {
      homePage {
        subscriptionForm {
          cta
          emailPlaceholder
          namePlaceholder
          privacyPolicy
        }
      }
    }
  }
`;

export default AlternativeSubscriptionForm;
