import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

import AppContext from "../context/AppContext";
import Navbar from "./Navbar";
import MobileNavbar from "./MobileNavbar";
import Footer from "./Footer";
import AlertMessage from "../components/AlertMessage";

const Layout = ({ children, innerPage }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query);

  const { alertState, setAlertState } = useContext(AppContext);

  return (
    <>
      <Navbar siteMetadata={siteMetadata} innerPage={innerPage || false} />
      {children}
      <Footer siteMetadata={siteMetadata} />
      <MobileNavbar siteMetadata={siteMetadata} />
      {alertState.message && (
        <AlertMessage
          message={alertState.message}
          successful={alertState.success}
          hidden={alertState.hidden}
          setAlertState={setAlertState}
        />
      )}
    </>
  );
};

const query = graphql`
  {
    site {
      siteMetadata {
        navbarLinks {
          social {
            facebook
            instagram
            linkedin
            twitter
            youtube
          }
          pages {
            link
            name
          }
        }
      }
    }
  }
`;

export default Layout;
