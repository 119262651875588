import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import respond from "../styles/abstracts/mediaqueries";

import SocialIcons from "../components/SocialIcons";
import AlternativeSubscriptionForm from "../components/AlternativeSubscriptionForm";

import footerLogo from "../images/footer-logo.svg";

const StyledFooter = styled.footer`
  background-color: var(--light-black);
  border-top: 10px solid var(--color-secondary);
  color: var(--white);
  //height: 10rem;
  display: flex;
  align-items: center;
  padding: 4rem 0;

  .container {
    display: grid;
    grid-template-areas:
      "logo logo logo links links form form form"
      "icons icons icons icons copy copy copy copy";
    grid-template-columns: repeat(8, 1fr);
    column-gap: 4rem;
    row-gap: 6rem;

    ${respond(
      "tab-port",
      css`
        grid-template-areas:
          "logo logo logo logo links links links links"
          ". . form form form form . ."
          "icons icons icons icons copy copy copy copy";
      `
    )}
    ${respond(
      "phone-port",
      css`
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, auto);
        grid-template-areas:
          "logo"
          "links"
          "form"
          "icons"
          "copy";
        align-items: center
        justify-items: center;
      `
    )}
  }

  .logo {
    grid-area: logo;

    ${respond(
      "tab-port",
      css`
        width: 100%;
      `
    )}
  }

  .form-container {
    grid-area: form;
  }
  a {
    color: var(--white);
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    grid-area: links;
    justify-self: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .social-icons {
    grid-area: icons;
  }

  .copyright {
    grid-area: copy;
    text-transform: uppercase;
    font-family: var(--alternative-font);
    font-size: 1.4rem;

    ${respond(
      "phone-port",
      css`
        text-align: center;
      `
    )}

    a {
      font-weight: 500;
    }
  }
`;

const Footer = ({
  siteMetadata: {
    navbarLinks: { social, pages },
  },
}) => {
  return (
    <StyledFooter>
      <div className="container">
        <img src={footerLogo} alt="Nicole Tabs signature with a XOXO" className="logo" />
        <ul>
          {pages.map(({ link, name }, i) => {
            return (
              <li key={i}>
                <Link to={link}>{name}</Link>
              </li>
            );
          })}
        </ul>
        <AlternativeSubscriptionForm className="form-container" />
        <SocialIcons socialLinks={social} className="social-icons" />
        <div className="copyright">
          &copy;{new Date().getFullYear()} Monarchy LLC. All Rights Reserved | <Link to="/privacy">Privacy Policy</Link>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
